import React from 'react';
import Layout from '../../components/layout';
import SEO from '../../components/seo';
import { titles } from '../../const/metaData';
import { Wrapper, TextContainer } from '../../styles/commonStyles';

const UnboxingCompetition: React.FunctionComponent = () => {
  return (
    <Layout>
      <SEO title={titles.unboxingCompetition} />
      <Wrapper>
        <TextContainer>
          <h2>Bó Unboxing Photograph Competition Terms and Conditions</h2>
          <ol>
            <li>
              Competition is open to Bó customers aged 18 or over who are UK
              residents, who are members of Facebook and members of our Facebook
              group at{' '}
              <a
                href="https://www.facebook.com/groups/thebocommunity"
                target="_blank"
              >
                www.facebook.com/groups/thebocommunity
              </a>
              . This competition is not open to employees of Bó/RBS Group.
            </li>
            <li>
              Entry is via Facebook only. One entry per person. Entries must be
              submitted by entering your details at{' '}
              <a href="www.facebook.com/groups/thebocommunity" target="_blank">
                www.facebook.com/groups/thebocommunity
              </a>{' '}
              by November 30th 2019 at 11:59pm. If the entry is submitted after
              this time, it will not be counted.
            </li>
            <li>
              Entries are not returnable. They, and the copyright for them,
              become the property of the promoter and entrants agree to execute
              such further documents, and do any act thatmay be necessary or
              desirable, to ensure ownership of such copyright vests in the
              Promoter.
            </li>
            <li>
              Entries not submitted in accordance with these rules, delayed, or
              altered entries will be disqualified. No responsibility is
              accepted for entries lost or delayed.
            </li>
            <li>
              The prize[s] will be awarded to the top three entrant[s] who, in
              line with the judges opinions, submits the best unboxing
              photograph of their Bó card. The judge's decision is final and no
              correspondence will be entered into.
            </li>
            <li>
              The prize[s] comprise[s] of a collection of Bó merchandise
              (lunchbox, notebook, pen). The prize is not transferable and no
              cash alternative is available.
            </li>
            <li>
              The winner[s] of the competition will be informed on Facebook by
              Direct Message (to the Facebook profile ID used by the winner at
              the point of entry). The winner’s name and county will be
              available following the close of the competition by sending a
              stamped addressed envelope to 65 Piccadilly, London, W1J 0AJ.
            </li>
            <li>
              The winner[s] agree[s] to take part in publicity in connection
              with this competition, and that their entry may be used by the
              promoter for promotional purposes, including, but not limited to,
              online, or the promoter’s social media sites.
            </li>
            <li>
              This promotion is in no way sponsored, endorsed or administered
              by, or associated with Facebook. Facebook is in no way responsible
              for any aspect of this promotion. However, all entrants shall
              comply with Facebook’s terms and conditions throughout this
              promotion.
            </li>
            <li>
              If a prize is unclaimed after reasonable efforts have been made to
              contact the winner[s] the promoter will be entitled to dispose of
              the prize as it sees fit without any liability to the winner[s]
              for having done so.
            </li>
            <li>
              The promoter reserves the right to alter, amend or foreclose the
              promotion without prior notice.
            </li>
            <li>
              All times referenced in these Terms and Conditions are to London,
              UK- time.
            </li>
            <li>The promoter is Bó, 65 Piccadilly, London W1J 0AJ.</li>
          </ol>
        </TextContainer>
      </Wrapper>
    </Layout>
  );
};

export default UnboxingCompetition;
